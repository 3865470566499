/* eslint-disable no-mixed-spaces-and-tabs */
import m, * as bacta from "bacta"
import { Input, InputAttrs } from "../sl/input"
import { AnyFunction } from "ramda"

type NumberAttrs = {
	type: "number"
	query: bacta.Store<number | null>
}

type TextAttrs = {
	type: "text"
	query: bacta.Store<string>
}

type TelAttrs = {
	type: "tel"
	query: bacta.Store<string>
}

type EmailAttrs = {
	type: "email"
	query: bacta.Store<string>
}

type DateAttrs =
	| {
			type: "date"
			query: bacta.Store<Date | null>
	  }
	| {
			type: "datetime-local"
			query: bacta.Store<Date | null>
	  }

type BaseAttrs = {
	sl?: Partial<InputAttrs>
	label?: string
	postop?: AnyFunction
}
export type HarthInputAttrs = BaseAttrs &
	(NumberAttrs | TextAttrs | DateAttrs | TelAttrs | EmailAttrs)

export const HarthInput: bacta.ClosureComponent<HarthInputAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs }, children }) => {
		return m(
			Input,
			{
				type: attrs.type,
				["onsl-input"](e) {
					if (attrs.type === "date" || attrs.type === "datetime-local") {
						attrs.query.update(
							() =>
								new Date(
									(e.target as HTMLInputElement)?.valueAsDate as unknown as Date
								)
									.toISOString()
									.slice(0, 10) as unknown as Date
						)
					} else if (attrs.type === "number") {
						attrs.query.update(() =>
							((e.target as HTMLInputElement)?.valueAsNumber as number)
								? (e.target as HTMLInputElement)?.valueAsNumber
								: null
						)
					} else {
						attrs.query.update(() => (e.target as HTMLInputElement)?.value)
					}

					if (attrs.postop) {
						attrs.postop()
					}
				},
				[attrs.type === "number"
					? "valueAsNumber"
					: attrs.type === "date" || attrs.type === "datetime-local"
					? "value"
					: "value"]: attrs.query.get(),
				...sl,
			},
			children
		)
	},
})
